import React from "react";
import Video from "../components/Video.jsx";

function Passion() {
  return (
    <>
      <Video
        name='Passion Policy Music Video (feat. Ricki Skizz & Ziggy Cowboy)'
        description='An insane display of raw talent, physical aggression, and an ungodly amount of pee. Ricki Skizz and Ziggy Cowboy seem to not be getting along and fight for some reason. Honestly, its still not clear to me what the fuck is going on here and I made the damn thing.'

        source='https://www.youtube.com/embed/rOQDFtRINFY?si=PFzrPXZJ5jzWoXi8&amp;controls=0'
      />
    </>
  );
}

export default Passion;