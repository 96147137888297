import React from "react";
import Video from "../components/Video.jsx";

function Internet() {
  return (
    <>
      <Video
        name='Internet'
        description='Oh my mothers fathers brother, YOURE CHOKING!!!'

        source='https://www.youtube.com/embed/jUq2iDJjBCo?si=VY16lUDu6lDD0NMP&amp;controls=0'
      />
    </>
  );
}

export default Internet;